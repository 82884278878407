body {
  color: #8e7555;
  letter-spacing: 1px;
  background-color: #000;
  margin: 0;
  padding: 0;
  font-family: Georgia, Times New Roman, Times, serif;
  line-height: 1.6em;
}

a, a:link, a:visited {
  color: #770726;
  font-weight: normal;
  text-decoration: none;
}

a:hover {
  color: #660;
  text-decoration: underline;
}

a.more {
  color: #770726;
  text-align: center;
  text-decoration: underline;
  display: block;
}

a.more span {
  letter-spacing: -3px;
  font-size: 14px;
}

a.more:hover {
  color: #660;
  text-decoration: none;
}

p {
  margin: 0 0 10px;
  padding: 0;
}

img {
  border: none;
}

blockquote {
  margin: 0 0 0 10px;
  font-style: italic;
}

cite, cite a, cite a:link, cite a:visited {
  color: #c63a03;
  font-weight: bold;
}

cite span {
  color: #8e7555;
  font-weight: 400;
}

em {
  color: #c63a03;
}

h1, h2, h3, h4, h5, h6 {
  color: #62260e;
  font-weight: normal;
}

h1 {
  margin: 0 0 30px;
  padding: 5px 0;
  font-size: 48px;
}

h2 {
  margin: 0 0 25px;
  padding: 5px 0;
  font-size: 34px;
}

h3 {
  margin: 0 0 20px;
  padding: 0;
  font-size: 24px;
}

h4 {
  margin: 0 0 15px;
  padding: 0;
  font-size: 20px;
}

h5 {
  margin: 0 0 10px;
  padding: 0;
  font-size: 16px;
}

h6 {
  margin: 0 0 5px;
  padding: 0;
  font-size: 12px;
}

.cleaner {
  clear: both;
}

.h10 {
  height: 10px;
}

.h20 {
  height: 20px;
}

.h30 {
  height: 30px;
}

.h40 {
  height: 40px;
}

.h50 {
  height: 50px;
}

.h60 {
  height: 60px;
}

.float_l {
  float: left;
}

.float_r {
  float: right;
}

.image_frame {
  border: 5px solid #a38d68;
  display: inline-block;
}

.image_fl {
  float: left;
  margin: 3px 20px 10px 0;
}

.image_fr {
  float: right;
  margin: 3px 0 0 20px;
}

.tmo_list {
  padding: 0 0 0 15px;
  list-style: disc;
}

.tmo_list li {
  color: #c63a03;
  margin-bottom: 8px;
}

.tmo_list li a {
  color: #c63a03;
  font-weight: normal;
  text-decoration: none;
}

.tmo_list li a:hover {
  text-decoration: underline;
}

.link_list {
  padding: 0;
  list-style: none;
}

.link_list li {
  color: #c63a03;
  border-bottom: 1px dashed #726e6e;
  margin-bottom: 8px;
}

.link_list li a {
  color: #c63a03;
  font-weight: normal;
  text-decoration: none;
}

.link_list li a:hover {
  text-decoration: underline;
}

.faq_row {
  margin: 0 0 20px;
  padding: 0;
}

.faq_row > h3 {
  line-break: loose;
  line-height: 1.8ch;
}

#templatemo_wrapper {
  justify-content: center;
  display: grid;
  position: relative;
}

.bg_top {
  z-index: -1000;
  background: url("templatemo_bg_top.0717e1ec.png");
  width: 100%;
  height: 570px;
  position: absolute;
  top: 0;
}

.bg_bottom {
  z-index: -1200;
  background: url("templatemo_bg_bottom.d4916287.png");
  width: 100%;
  height: 270px;
  position: absolute;
  bottom: 0;
}

#templatemo_menu {
  float: right;
  background: url("templatemo_menu.4fcc897b.png") no-repeat;
  width: 966px;
  height: 75px;
  margin-top: 40px;
  margin-bottom: 20px;
}

#templatemo_menu ul {
  margin: 0;
  padding: 0 40px;
  list-style: none;
}

#templatemo_menu ul li {
  float: left;
  background: url("templatemo_menu_divider.2afd4b37.png") 100% no-repeat;
  width: 164px;
  height: 75px;
  margin: 0;
  padding: 0;
  display: block;
}

#templatemo_menu ul li a {
  float: left;
  color: #62260e;
  text-align: center;
  border: none;
  outline: none;
  width: 160px;
  height: 75px;
  padding-right: 4px;
  font-size: 16px;
  font-style: italic;
  font-weight: 700;
  line-height: 75px;
  text-decoration: none;
  display: block;
}

#templatemo_menu ul li a:hover, #templatemo_menu ul .current {
  background: url("templatemo_menu_hover.8df707ef.png") center no-repeat;
}

#templatemo_menu .last {
  background: none;
}

#templatemo_header {
  clear: both;
  background: url("templatemo_header.5c8e10a2.png") top no-repeat;
  width: 966px;
  height: 170px;
}

#site_title {
  float: left;
  width: 400px;
  margin: 60px 0 0 40px;
}

#site_title h1 {
  margin: 0;
  padding: 0;
}

#site_title h1 a {
  color: #88553a;
  background: url("rhabdomancer_logo.6b9e9a74.png") 0 0 no-repeat;
  width: 334px;
  padding: 70px 0 0 20px;
  font-size: 18px;
  display: block;
}

#social {
  float: right;
  margin: 60px 40px 0 0;
}

#social a {
  float: left;
  margin-left: 10px;
}

#templatemo_main {
  background: url("templatemo_main.e27f3856.png") repeat-y;
  width: 900px;
  padding: 33px 33px 0;
}

#templatemo_main_bottom {
  background: url("templatemo_main_bottom.0e6b65d8.png") no-repeat;
  width: 966px;
  height: 143px;
}

#sidebar {
  float: left;
  width: 280px;
}

#content {
  float: right;
  width: 580px;
}

.cbox_fw {
  clear: both;
  background: url("templatemo_hr2.9f7c2505.png") bottom no-repeat;
  margin-bottom: 40px;
  padding-bottom: 20px;
}

.cbox_fws {
  clear: both;
  background: url("templatemo_hr1.d1fc51bd.png") bottom no-repeat;
  margin-bottom: 40px;
  padding-bottom: 20px;
}

.cbox_ncontent {
  clear: both;
  padding-bottom: 20px;
}

.cbox_last {
  background: none;
  margin: 0;
  padding: 0;
}

.cbox_w280 {
  width: 280px;
  margin-bottom: 20px;
}

.cbox_w280 img {
  margin-bottom: 10px;
}

#nne {
  margin-bottom: 40px;
}

#nne h3 {
  border-bottom: 1px solid #5f2712;
  padding-bottom: 20px;
}

ul.nne_box {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.nne_box li {
  border-left: 5px solid #8e7555;
  margin: 0 0 20px;
  padding: 0 0 0 20px;
}

ul.nne_box li span {
  color: #850d2f;
  display: block;
}

ul.nne_box li a {
  color: #8e7555;
}

ul.photo_gallery {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.photo_gallery li {
  float: left;
  margin: 0 5px 15px 0;
  padding: 0;
  display: block;
}

ul.photo_gallery .rmp {
  margin: 0 0 15px;
}

#contact_form {
  padding: 0;
}

#contact_form form {
  margin: 0;
  padding: 0;
}

#contact_form form .input_field {
  color: #000;
  background: #fff;
  border: 1px solid #960;
  width: 400px;
  padding: 5px;
}

#contact_form form label {
  width: 100px;
  margin-top: 15px;
  margin-bottom: 3px;
  font-size: 14px;
  display: block;
}

#contact_form form textarea {
  color: #000;
  background: #fff;
  border: 1px solid #960;
  width: 400px;
  height: 200px;
  padding: 5px;
  font-family: Arial, Helvetica, sans-serif;
}

#contact_form form .submit_btn {
  cursor: pointer;
  color: #420;
  background: #da7;
  border: 1px solid #960;
  margin: 10px 10px 10px 60px;
  padding: 7px 14px;
}

#contact_form form .submit_btn:hover {
  color: #fff;
  background: #960;
}

#contact_form form .submit_btn:active {
  color: #fff;
  background: #999;
}

#member_login {
  background: url("templatemo_login.049121a5.png");
  width: 230px;
  height: 170px;
  padding: 25px;
}

#member_login h3 {
  color: #494847;
  border-bottom: 1px solid #b6aa99;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

#member_login form {
  margin: 0;
  padding: 0;
}

#member_login label {
  float: left;
  color: #62260e;
  width: 80px;
}

#member_login .txt_field {
  float: right;
  color: #000;
  font-variant: normal;
  background: url("templatemo_txtfield.b69b8b66.png") no-repeat;
  border: none;
  width: 132px;
  height: 29px;
  padding: 0 5px;
  font-size: 12px;
  line-height: 30px;
  display: block;
}

#member_login .sub_btn {
  float: right;
  cursor: pointer;
  text-align: center;
  white-space: pre;
  color: #000;
  background: url("templatemo_login_btn.3692929a.png");
  border: none;
  width: 87px;
  height: 32px;
  font-size: 12px;
  display: block;
}

#templatemo_footer {
  clear: both;
  color: #999494;
  text-align: center;
  width: 900px;
  padding: 0 33px 20px;
}

#templatemo_footer a {
  color: #999494;
}

#canvas-wrapper {
  margin: 20px;
  display: inline-block;
  position: relative;
}

#my_canvas-container {
  z-index: 10;
  position: absolute;
  inset: 0;
}

#my_canvas-container > #gamecanvas {
  width: 100%;
  height: 100%;
}

#game {
  text-align: center;
  border: 5px solid #62270e;
}

/*# sourceMappingURL=about.a5394c81.css.map */
